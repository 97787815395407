export const addresses = {
  421613: {
    TokenIDO: "0x351c4ea9fd35dD4c2A1D952Ebb0Df4D3FB516227",
    IEDE: "0xF77b080664B5eb028AF31Fc94903c0fC773b5b67",
    WETH: "0x0f0f6af376502F5034016E7AA3F66dA805773Eda"
  },
  42161: {
    TokenIDO: "0x57b78bff7297635414c55443ff4e3dae8a55a74a",
    WETH: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
  },
};
