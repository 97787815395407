import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { RootState } from "../store";
import { setAll } from "utils/helpers";
import { ethers } from "ethers";
import TokenIDOAbi from "../abis/TokenIDO.json";
import IERC20Abi from "../abis/IERC20.json";
import { addresses } from "configs/constants";

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async ({ chainID, provider }: IBaseAddressAsyncThunk) => {
    console.log("loadAppDetails")
    const TokenIDOContract = new ethers.Contract(addresses[chainID].TokenIDO, TokenIDOAbi.abi, provider);
    const endOfSale = await TokenIDOContract.IDOWhiteListEndTimestamp();
    const startTime = await TokenIDOContract.IDOStartTimestamp();
    const claimStartTime = await TokenIDOContract.ClaimStartTimestamp();

    const fundraiseGoal = await TokenIDOContract.MaxIDOPayInAmount();

    const WETHContract = new ethers.Contract(addresses[chainID].WETH, IERC20Abi.abi, provider);
    const allocation = await WETHContract.balanceOf(addresses[chainID].TokenIDO);

    const price = await TokenIDOContract.whiteListPrice();

    const minhitelistSinglePayInAmount = await TokenIDOContract.MinhitelistSinglePayInAmount();
    const maxWhitelistSinglePayInAmount = await TokenIDOContract.MaxWhitelistSinglePayInAmount();

    return {
      endOfSale,
      startTime,
      claimStartTime,
      fundraiseGoal: ethers.utils.formatEther(fundraiseGoal),
      allocation: ethers.utils.formatEther(allocation),
      price: ethers.utils.formatEther(price),
      minhitelistSinglePayInAmount: ethers.utils.formatEther(minhitelistSinglePayInAmount),
      maxWhitelistSinglePayInAmount: ethers.utils.formatEther(maxWhitelistSinglePayInAmount),
    };
  },
);

const initialState = {
  loading: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
