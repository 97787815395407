import { Box, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import { ConnectKitButton } from 'connectkit'
import styled from 'styled-components'

import './header.scss'
import Logo from '../../assets/images/logo.svg'
import LogoM from '../../assets/images/logo-m.svg'
import Wallet from '../../assets/images/wallet.svg'

export default function Header(props) {
  const isSmallScreen = useMediaQuery('(max-width: 1080px)')
  const isVerySmallScreen = useMediaQuery('(max-width: 680px)')

  const ConnectButton = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 32px 8px 20px;
    color: #9b96ff;
    background: transparent;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Poppins-Medium';
    border-radius: 9px;
    border: 1px solid #9b96ff;
    transition: 200ms ease;
    &:hover {
    }
    &:active {
    }
  `

  return (
    <div className="header">
      <Box display="flex" justifyContent="center" style={{ height: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" className="containerBox">
          <img src={isSmallScreen ? LogoM : Logo} alt="Logo" className="" />
          <div className="">
            <ConnectKitButton.Custom>
              {({ isConnected, show, truncatedAddress, ensName }) => {
                return (
                  <ConnectButton onClick={show}>
                    <img src={Wallet} alt="Wallet" className="" style={{ height: '12px' }} />
                    <div className="ml-8">{isConnected ? ensName ?? truncatedAddress : 'Connect Wallet'}</div>
                  </ConnectButton>
                )
              }}
            </ConnectKitButton.Custom>
          </div>
        </Box>
      </Box>
    </div>
  )
}
