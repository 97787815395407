import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { RootState } from "../store";
import { setAll } from "utils/helpers";
import { ethers } from "ethers";
import { addresses } from "configs/constants";
import TokenIDOAbi from "../abis/TokenIDO.json";
import IERC20Abi from "../abis/IERC20.json";

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ chainID, provider, address }: IBaseAddressAsyncThunk) => {
    console.log("loadAccountDetails---");
    const balance_eth = await provider.getBalance(address);
    console.log("balance_eth:", ethers.utils.formatEther(balance_eth))

    const TokenIDOContract = new ethers.Contract(addresses[chainID].TokenIDO, TokenIDOAbi.abi, provider);
    const balance_EDE = await TokenIDOContract.whitelistPurchaseAmount(address)
    console.log("balance_EDE:", ethers.utils.formatEther(balance_EDE))
    const isWhiteListed = await TokenIDOContract.whiteListed(address);
    console.log("isWhiteListed:", isWhiteListed)
    return {
      isWhiteListed,
      balance_eth: ethers.utils.formatEther(balance_eth),
      balance_EDE: ethers.utils.formatEther(balance_EDE)
    };
  },
);
interface IAccountSlice {
  loading: boolean;
}

const initialState: IAccountSlice = {
  loading: false,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.account;
export default accountSlice.reducer;
export const { fetchAccountSuccess } = accountSlice.actions;
export const getAccountState = createSelector(baseInfo, account => account);
