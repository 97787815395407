import { lazy, Suspense, useCallback, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import LoadingSplash from './components/LoadingSplash'
import Header from './components/Header'

import './assets/fonts/fonts.css'
import './assets/css/common.scss'
import './assets/css/toast.scss'
import { Box } from "@mui/material";
import { useAccount, useNetwork, useProvider } from "wagmi";
import { NETWORKID } from "constants/configuration";
import { batch, useDispatch } from "react-redux";
import { loadAppDetails } from "slices/AppSlice";
import { loadAccountDetails } from "slices/AccountSlice";
const Ido = lazy(() => import('./views/ido'))

function App() {

  const { address, isConnected } = useAccount()
  const provider = useProvider()
  const { chain } = useNetwork()
  const chainID = chain?.id || NETWORKID
  const dispatch = useDispatch()

  function loadDetails(whichDetails) {
    let loadProvider = provider
    if (whichDetails === 'app') {
      loadApp(loadProvider)
    }

    if (whichDetails === 'account' && address && isConnected) {
      loadAccount(loadProvider)
    }
  }
  const loadApp = useCallback(
    loadProvider => {
      batch(() => {
        dispatch(loadAppDetails({ chainID: chainID, provider: loadProvider }))
      })
    },
    [isConnected, chainID]
  )
  const loadAccount = useCallback(
    loadProvider => {
      batch(() => {
        dispatch(loadAccountDetails({ chainID: chainID, address, provider: loadProvider }))
      })
    },
    [isConnected, address, chainID]
  )
  useEffect(() => {
    loadDetails('app')
  }, [isConnected, chainID])

  useEffect(() => {
    if (isConnected) {
      loadDetails('account')
    }
  }, [isConnected, address, chainID])


  return (
    <div className="App">
      <Suspense fallback={<LoadingSplash></LoadingSplash>}></Suspense>
      <ToastContainer limit={1} position="bottom-right" closeButton={false} newestOnTop={false} pauseOnFocusLoss closeOnClick={false} draggable={false} icon={false} />
      <Header />
      <Box display="flex" justifyContent='center' className="">
        <div className="containerBox">
          <Ido />
          {/* <Switch>
            <Route exact path="/">
              <Ido />
            </Route>
            <Route exact path="/">
              <Redirect to="/" />
            </Route>
          </Switch> */}
        </div>
      </Box>
    </div>
  );
}

export default App;
