import React from 'react';
import { bsc, bscTestnet, arbitrumGoerli, arbitrum } from 'wagmi/chains';
import { createClient } from 'wagmi';
import { getDefaultClient } from 'connectkit';

// const alchemyId = process.env.ALCHEMY_ID;
// Wagmi's "createClient" has a default ALCHEMY_ID out of the box and that's what will be used here since no .env file is provided. In production you'd use your Alchemy or Infura key.

const chains = [arbitrum];

const client = createClient(
    getDefaultClient({
        autoConnect: true,
        appName: 'EDE',
        // alchemyId,
        chains
    })
);

export { chains, client };