import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConnectKitProvider } from "connectkit";
import { HashRouter } from "react-router-dom";
import './index.css';
import App from './App';
import { client } from "./configs/chainClient";
import { WagmiConfig } from "wagmi";
import store from "./store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WagmiConfig client={client}>
        <ConnectKitProvider >
          <HashRouter basename={"/"}>
            <App />
          </HashRouter>
        </ConnectKitProvider>
      </WagmiConfig>
    </Provider>
  </React.StrictMode>
);

